export default defineNuxtRouteMiddleware(async () => {
  const { isAuthenticated, isWkSession, isMMSession, goToUserDefaultPage } =
    await useAuth();
  const { authenticationData } = await useAuthenticationData();

  if (isAuthenticated.value && (!isWkSession.value || isMMSession.value)) {
    return goToUserDefaultPage();
  }
  if (
    !isAuthenticated.value &&
    authenticationData.value &&
    (authenticationData.value.options.wkLoginOnly ||
      authenticationData.value.options.mmLoginOnly ||
      !authenticationData.value.options.registrationEnabled)
  ) {
    return navigateTo("/login");
  }
});
